import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@app/_models';
import { map } from 'rxjs/operators';



@Injectable({ providedIn: 'root' })
export class UserService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    constructor(private http: HttpClient) {

        // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        // this.currentUser = this.currentUserSubject.asObservable();
    }


    getAll() {
        return this.http.get<User[]>(`http://api.virtuvian.com/transaction/getTables`);
    }

    getTable():Observable<any> {
        
        let header1: HttpHeaders;
        header1 = new HttpHeaders().set('Content-Type', 'application/json');
        
        return this.http.get(`http://api.virtuvian.com/transaction/getTables`,{ headers: header1 })
          
      
        .pipe(map((result) =>result));
          
    }
}
