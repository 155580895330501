import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    loading = false;
    users: User[];

    constructor(private userService: UserService, authenticationService: AuthenticationService) { }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnInit() {
        // this.loading = true;

        // this.userService.getAll().pipe(first()).subscribe(users => {
        //     this.loading = false;
     
        //     this.users = users
        // });
    }
}
