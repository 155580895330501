import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '@app/utils/endpoint';
@Injectable({
  providedIn: 'root'
})
export class ViewTableDetailsService {

constructor(private http: HttpClient) { }


getUserDetailsByMobileNumber(mobileNumber) {
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
  // tslint:disable-next-line: max-line-length
  return this.http.post<any>(`http://api.virtuvian.com/transaction/getCustomerByMobile`, JSON.stringify(mobileNumber), { headers: header1 })
      .pipe(map(userDetails => {
          

         
          return userDetails;
      }));
}



printKotDetails(data) {
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
  // tslint:disable-next-line: max-line-length
  return this.http.post<any>(`http://api.virtuvian.com/transaction/printKotDetails`, JSON.stringify(data), { headers: header1 })
      .pipe(map(userDetails => {
          

         
          return userDetails;
      }));
}

getTaxDetails(data) {
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
  // tslint:disable-next-line: max-line-length
  return this.http.post<any>(` http://api.virtuvian.com/transaction/getTaxDetails`, JSON.stringify(data), { headers: header1 })
      .pipe(map(userDetails => {
          

         
          return userDetails;
      }));
}

getBookingDetails(data) {
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
  // tslint:disable-next-line: max-line-length
  return this.http.post<any>(`http://api.virtuvian.com/transaction/getBookingDetails`, JSON.stringify(data), { headers: header1 })
      .pipe(map(getBookingDetails => {
          

         
          return getBookingDetails;
      }));
}
viewKotDetails(data) {
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
  // tslint:disable-next-line: max-line-length
  return this.http.post<any>(`http://api.virtuvian.com/transaction/getKotDetails`, JSON.stringify(data), { headers: header1 })
      .pipe(map(getBookingDetails => {
          

         
          return getBookingDetails;
      }));
}


viewFoodCategories() {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(`http://api.virtuvian.com/ddlBinder/getCategory`, { headers: header1 })
        .pipe(map(getBookingDetails => {
            
  
           
            return getBookingDetails;
        }));
  }
  getTotalFodBill(data) {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post<any>(`http://api.virtuvian.com/transaction/printBillTotalDetail`,JSON.stringify(data), { headers: header1 })
        .pipe(map(getBookingDetails => {
            
  
           
            return getBookingDetails;
        }));
  }
  getunsettledTakeAwayBookingId( ) {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
   
    return this.http.get<any>(`http://api.virtuvian.com/transaction/getBookingIdTakeAway`, { headers: header1 })
        .pipe(map(getBookingDetails => {
            
  
           
            return getBookingDetails;
        }));
  }
  getAllPayementModes( ) {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
   
    return this.http.get<any>(`http://api.virtuvian.com/ddlBinder/getPaymentMode `, { headers: header1 })
        .pipe(map(getPaymentMode => {
            
  
           
            return getPaymentMode;
        }));
  }
  getAllAgents( ) {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
   
    return this.http.get<any>(`http://api.virtuvian.com/ddlBinder/getAgent`, { headers: header1 })
        .pipe(map(getAgent => {
            
  
           
            return getAgent;
        }));
  }
  getAllUnitsById(data) {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
   
    return this.http.post<any>(`http://api.virtuvian.com/ddlBinder/getUnitByItemId`,JSON.stringify(data), { headers: header1 })
        .pipe(map(getUnitByItemId => {
            
  
           
            return getUnitByItemId;
        }));
  }
  getMenuPrice(data) {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
   
    return this.http.post<any>(`http://api.virtuvian.com/transaction/getMenuPrice`,JSON.stringify(data), { headers: header1 })
        .pipe(map(getMenuPrice => {
            
  
           
            return getMenuPrice;
        }));
  }

  addItemInKot(data) {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
   
    return this.http.post<any>(` http://api.virtuvian.com/transaction/save`,JSON.stringify(data), { headers: header1 })
        .pipe(map(getMenuPrice => {
            
   
           
            return getMenuPrice;
        }));
  }
  getMenuItems(data) {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
   
    return this.http.post<any>(`http://api.virtuvian.com/ddlBinder/getItemByCategoryId`,JSON.stringify(data), { headers: header1 })
        .pipe(map(getMenuItem=> {
            
  
           
            return getMenuItem;
        }));
  }
  getDropDownLists(data){
        
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.getDataForDropDown,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));
      
  }
  getAutoCompleteFoodName(data){
        
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.getFoodName,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));
      
  }
  viewCustomerDetails(data)
  {
         
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.getCustomerDetails,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));
  }
  saveData(data)
  {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.saveData,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));

  }
  printBill(data)
  {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.printBill,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));

  }
  checkPin(data)
  {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.checkPin,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));

  }
  reKotPrint(data)
  {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.reKotPrint,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));

  }
  resetTable(data)
  {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.saveData,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));

  }
  saveOrder(data)
  {
    let header1: HttpHeaders;
    header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
    return this.http.post(AppSettings.saveData,JSON.stringify(data),{ headers: header1 })
      
  
    .pipe(map((result) =>result));

  }
}
