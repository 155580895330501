import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@app/_models';
import { map } from 'rxjs/operators';
import { AppSettings } from '@app/utils/endpoint';
 
@Injectable({
  providedIn: 'root'
})
export class ViewTablesService {
  public tableList:any=[];
  public selectedTableData:any=undefined
  constructor(private http: HttpClient) { }

 

getTable():Observable<any> {
        
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
 
  return this.http.get(AppSettings.getTables,{ headers: header1 })
    

  .pipe(map((result) =>result));
    
}
getTodayStatus():Observable<any> {
        
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
  return this.http.get(`http://api.virtuvian.com/transaction/getTodayStatus`,{ headers: header1 })
    

  .pipe(map((result) =>result));
    
}

getActiveTableItems():Observable<any> {
        
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
  return this.http.get(`http://api.virtuvian.com/transaction/getActiveTableItems`,{ headers: header1 })
    

  .pipe(map((result) =>result));
    
}

getPendingHomeDeliveries():Observable<any> {
        
  let header1: HttpHeaders;
  header1 = new HttpHeaders().set('Content-Type', 'application/json');
  
  return this.http.get(`http://api.virtuvian.com/transaction/getPendingHomeDeliveries`,{ headers: header1 })
    

  .pipe(map((result) =>result));
    
}

saveSelectedTableData(data)
{
  this.selectedTableData=data
}
getSelectedTableData()
{
  return this.selectedTableData
}
}

