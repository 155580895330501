export class AppUtils {


    public static getToastDatafromApi(message) {

        let messageAr = message.split('|');
         let  msgText = messageAr[0];
          let toastStatus="";
          let payload={}
          let  msgType = messageAr[1];  
         if (msgType == "1") {
            toastStatus="success"
        }
        else if (msgType == "2") {
            toastStatus="info" 
        }
        else if (msgType == "3") {
            toastStatus="warning"
        }
        else {
            toastStatus="error" 
        }
        payload["toastStatus"]=toastStatus,
        payload["msgText"]=msgText,
        payload["msgType"]=msgType
        return payload;
    }

     
}