import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ViewTablesService } from './view-tables.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ViewTableDetailsService } from '../view-table-details/view-table-details.service';
import { AppUtils } from '@app/utils/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-tables',
  templateUrl: './view-tables.component.html',
  styleUrls: ['./view-tables.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ViewTablesComponent implements OnInit {

  constructor(private viewTablesService: ViewTablesService, private viewTableDetailsService: ViewTableDetailsService, private router: Router,private toastr: ToastrService) { 
    this.getTables()
  }
  public tabeList: any = []
  public todayStatus: any = {}
  public homeDeliveries: any = []
  public activeTableItem: any = []

  isBillPrinted: boolean = false;
  availablePaymentModes: any = [];
  isSettlePopUpOpen: boolean = false;


  settlePayementPayLoad: any = {};
  isAmountEnterdValidated: boolean = false;
  selectedPayementMode: any = {'id':0,'value':'Payment Mode'};
  recievedAmount: number = 0
  returnAmount: number = 0;
  settlePayementFinalPayLoad: any = {};
  bookingTaxDetails: any = undefined;
  showCancelOrderPopup: boolean = false;
  reasonForCancel: string = "";
  isDeliverd: boolean = false;
  isAmountChangeRequired: boolean = false;
  inputCash: number = 0
  inputCard: number = 0
  inputPaytm: number = 0
  inputVoucher: number = 0
  inputOnline: number = 0
  inputSwiggy: number = 0
  inputZomato: number = 0
  inputUber: number = 0
  inputOnlneKaka: number = 0
  inputOwner: number = 0
  inputCredit: number = 0;
  
  ngOnInit() {
    //  this.tabeList=this.viewTablesService.getTableList();
  
    this.getTodayStatus()
    this.getHomeDelieveries()
    this.getActiveTableItems()
  }
  getTables() {
    this.viewTablesService.getTable()
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            this.tabeList = data;
          }

        },
        error => {


        });
  }
  getTodayStatus() {
    this.viewTablesService.getTodayStatus()
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            this.todayStatus = data[0]
          }

        },
        error => {


        });
  }
  getHomeDelieveries() {
    this.viewTablesService.getPendingHomeDeliveries()
      .subscribe(
        data => {
          if (data) {
            this.homeDeliveries = data

          }
          else {
            this.homeDeliveries = []
          }


        },
        error => {


        });
  }
  getActiveTableItems() {
    this.viewTablesService.getActiveTableItems()
      .subscribe(
        data => {
          if (data) {
            this.activeTableItem = data
          }


        },
        error => {


        });
  }
  handleTableClick(selectedTable) {
    this.viewTablesService.saveSelectedTableData(selectedTable)
    this.router.navigate([`/table-details/${selectedTable.bookingId}`]);
  }
  handlePrintBillApi(data) {
    //  this.isBillPrinted=true
    let payload = { "bookingType": "Delivery", "bookingMasterID": data.bookingId, "optMode": "updateDeliveryStatus", "Status": "On Way" }

    this.viewTableDetailsService.saveOrder(payload).subscribe(data => {
      this.viewTableDetailsService.printBill(payload).subscribe(data1 => {
        this.getHomeDelieveries()
        this.printBilll(data1[0].bill)
      })

    })

  }
  printBilll(data) {
   
    let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    // newWindow.addEventListener("afterprint",this.handlePrintCancel)
    newWindow.document.open();
    newWindow.document.write(`
  <html>
      <head>
        <title>Print tab</title>
        <style>
        
        </style>
      </head>
      <body  onload="window.print();window.close()">${data}   
      </body>
    </html>
  `);
    newWindow.document.close();
  }
  hanldeSettleBill(data) {

    this.isSettlePopUpOpen = true;
    
    this.settlePayementFinalPayLoad = {}
    this.settlePayementPayLoad = {}
    this.getAllPayementModes()
    this.bookingTaxDetails = data
    this.bookingTaxDetails.finalAmount=data.TotalAmount;
    this.returnAmount = 0
    this.recievedAmount = data["TotalAmount"]
  } 
  //v1
  // calculateRecivedAmount(amount, type) {

  //   let finalAmout = 0;

  //   this.isAmountEnterdValidated = false
  //   if (amount) {
  //     this.settlePayementPayLoad = { ...this.settlePayementPayLoad, [type]: [amount] }

  //   }
  //   if (this.selectedPayementMode.value !== "All") {
  //     if (amount < this.bookingTaxDetails.TotalAmount) {

  //     }
  //     else {
  //       let payLoad = "";
  //       for (let i in this.settlePayementPayLoad) {

  //         payLoad = i + '~' + this.settlePayementPayLoad[i]
  //       }
  //       if (amount > this.bookingTaxDetails.TotalAmount) {
  //         this.returnAmount = amount - parseInt(this.bookingTaxDetails.TotalAmount);
  //         this.recievedAmount = amount
  //       } else {
  //         this.returnAmount = 0
  //         this.recievedAmount = amount
  //       }

  //       this.isAmountEnterdValidated = true
  //       this.settlePayementFinalPayLoad = payLoad

  //     }
  //   }
  //   else {

  //     for (let i in this.settlePayementPayLoad) {

  //       finalAmout = Number(parseInt(this.settlePayementPayLoad[i]) + finalAmout);
  //     }
  //     if (finalAmout < this.bookingTaxDetails.TotalAmount) {

  //     }
  //     else {
  //       if (finalAmout > this.bookingTaxDetails.TotalAmount) {
  //         this.returnAmount = finalAmout - parseInt(this.bookingTaxDetails.TotalAmount);
  //         this.recievedAmount = finalAmout
  //       }
  //       else {
  //         this.returnAmount = 0
  //         this.recievedAmount = 0
  //       }
  //       let payLoad = "";
  //       for (let i in this.settlePayementPayLoad) {

  //         payLoad = payLoad + (i + '~' + this.settlePayementPayLoad[i] + "|")
  //       }
  //       this.settlePayementFinalPayLoad = payLoad
  //       this.isAmountEnterdValidated = true


  //     }
  //   }

  // }


  // getAllPayementModes() {
  //   let PayLoad = { "optMode": "bindPaymentMode" }
  //   this.viewTableDetailsService.getDropDownLists(PayLoad).subscribe(data => {

  //     this.availablePaymentModes = data;

  //   })
    
  // }
  getAllPayementModes() {
    this.isSettlePopUpOpen = false;
    let PayLoad = { "optMode": "bindPaymentMode" }
    this.viewTableDetailsService.getDropDownLists(PayLoad).subscribe(data => {
      let tempData: any = [];
      tempData = data;

      this.availablePaymentModes = tempData.map(function (el) {
        let o = Object.assign({}, el);
        o["Amount"] = 0;
        o["selected"] = false;
        o["FinalAmountEnterd"] = "";
        o["isDisabled"] = false;
        return o;
      })
      
      
      this.isSettlePopUpOpen = true;
      
    })

  }
  handleSettleBill() {



    let payLoad = { "bookingType": "Delivery", "bookingMasterID": this.bookingTaxDetails.bookingId, "optMode": "updateDeliveryStatus", "paymentDetail": this.settlePayementFinalPayLoad, "AmountPaid": this.recievedAmount, "PaymentMode": this.selectedPayementMode["value"], "refundAmount": this.returnAmount, "Status": "Delivered" }
    this.viewTableDetailsService.addItemInKot(payLoad).subscribe(data => {
      this.isSettlePopUpOpen = false;
      this.getHomeDelieveries();
     this.selectedPayementMode = {'id':0,'value':'Payment Mode'};
     let toastData = AppUtils.getToastDatafromApi(data[0].msg)
     if (toastData["msgType"] == "1") {
      this.toastr.success(toastData["msgText"])
     
    }
    else if (toastData["msgType"] == "2") {
      this.toastr.info(toastData["msgText"])
    }
    else if (toastData["msgType"] == "3") {
      this.toastr.warning(toastData["msgText"])
    }
    else {
      this.toastr.error(toastData["msgText"])
    }




    })
  }

  // handlePayementChange(data) {



  //   this.returnAmount = 0;


  //   this.recievedAmount = this.bookingTaxDetails.TotalAmount;
  //   let inputVar = `input${data.value}`
  //   if (data.value !== "All") {
  //     this.settlePayementPayLoad = {}
  //     this.settlePayementFinalPayLoad = {}
  //   }


  //   if (data.value === "All" || data.value === "Cash") {
  //     this.isAmountChangeRequired = true;
  //   }
  //   else {
  //     this.isAmountChangeRequired = false;
  //   }
  //   if (data.value === "All") {
  //     this.inputCash = 0
  //     this.inputCard = 0
  //     this.inputPaytm = 0
  //     this.inputVoucher = 0
  //     this.inputOnline = 0
  //     this.inputSwiggy = 0
  //     this.inputZomato = 0
  //     this.inputUber = 0
  //     this.inputOnlneKaka = 0
  //     this.inputOwner = 0
  //     this.inputCredit = 0
  //     this.isAmountEnterdValidated = false;
  //     this.returnAmount = 0
  //     this.recievedAmount = 0
  //   }
  //   else {
  //     this.calculateRecivedAmount(this.bookingTaxDetails.TotalAmount, data.id)
  //     this[inputVar] = this.bookingTaxDetails.TotalAmount
  //   }

  // }
  handeAutoSettleData(data) {



    this.returnAmount = 0;


    this.recievedAmount = this.bookingTaxDetails.finalAmount;
    let inputVar = `input${data.value}`
    if (data.value !== "All") {
      this.settlePayementPayLoad = {}
      this.settlePayementFinalPayLoad = {}
    }


    if (data.value === "All" || data.value === "Cash") {
      this.isAmountChangeRequired = true;
    }
    else {
      this.isAmountChangeRequired = false;
    }
    if (data.value === "All") {
      this.inputCash = 0
      this.inputCard = 0
      this.inputPaytm = 0
      this.inputVoucher = 0
      this.inputOnline = 0
      this.inputSwiggy = 0
      this.inputZomato = 0
      this.inputUber = 0
      this.inputOnlneKaka = 0
      this.inputOwner = 0
      this.inputCredit = 0
      this.isAmountEnterdValidated = false;
      this.returnAmount = 0
      this.recievedAmount = 0
    }
    else {
      let tempObject = { "FinalAmountEnterd": this.bookingTaxDetails.finalAmount, "id": data.id }
      this.calculateRecivedAmount(tempObject)
      //to freeze the same amount this[inputVar] = this.bookingTaxDetails.finalAmount
    }

  }


  handleCloseSettlePopUp() {
    this.isSettlePopUpOpen = false;
  }
  handleCancelOrder(data) {
    this.showCancelOrderPopup = true
    this.bookingTaxDetails = data
  }
  confirmOrderDeletion() {

    let payload = { "bookingType": "Delivery", "bookingMasterID": this.bookingTaxDetails.bookingId, "Status": "Cancelled", "optMode": "updateDeliveryStatus", "CancellationRemark": this.reasonForCancel }
    this.viewTableDetailsService.saveData(payload).subscribe(data => {
      let toastData = AppUtils.getToastDatafromApi(data[0].msg)
      if (toastData["msgType"] == "1") {
       this.toastr.success(toastData["msgText"])
      
     }
     else if (toastData["msgType"] == "2") {
       this.toastr.info(toastData["msgText"])
     }
     else if (toastData["msgType"] == "3") {
       this.toastr.warning(toastData["msgText"])
     }
     else {
       this.toastr.error(toastData["msgText"])
     }
     this.reasonForCancel="";
      this.showCancelOrderPopup = false;
      this.getHomeDelieveries()
    })
  }
  closeDeletePopup() {
    this.showCancelOrderPopup = false;
  }
  handleEditOrderClick() {
    // this.router.navigate([`/table-details/${selectedTable.bookingId}`]);
  }
  compareById(o1,o2){
     
    if(o1 == null || o2 == null){
      return false;
    }
    return o1.id === o2.id;
  }
  handleEditOrder(orderDetails)
  {
    this.viewTablesService.saveSelectedTableData(orderDetails)
    this.router.navigate([`/transactions/Delivery`]);
 
  }
  handleSelectePaymentMode(selectedModeData) {

    let count = 0;
    let isAmountEnterdValidated = false;
    let tempAmount = 0;
    this.availablePaymentModes.forEach(element => {
      if (element.FinalAmountEnterd) {
        tempAmount = element.FinalAmountEnterd + tempAmount
      }

      if (element.id === selectedModeData.id) {

        element.selected = true;
      }
      if (element.selected === true) {
        count = count + 1
      }


    });

    if (tempAmount < this.bookingTaxDetails.finalAmount) {
      if (count === 1) {
        this.availablePaymentModes.forEach(element => {
          if (element.id === selectedModeData.id) {
            element.FinalAmountEnterd = this.bookingTaxDetails.finalAmount
            if (element.FinalAmountEnterd != 0) {
              this.calculateRecivedAmount(element)
            }
            // else{
            //   element.selected=false;
            // }
          }
          else {
            element.isDisabled = true;
          }


        });
      }

      //multiple payement mode selected
      else {
        let totalAmountEnterd = 0;
        this.availablePaymentModes.forEach(element => {
          if (element.FinalAmountEnterd) {
            totalAmountEnterd = element.FinalAmountEnterd + Number(totalAmountEnterd);

          }
          if (element.id === selectedModeData.id) {

            element.FinalAmountEnterd = this.bookingTaxDetails.finalAmount - totalAmountEnterd;
            if (element.FinalAmountEnterd != 0) {
              this.calculateRecivedAmount(element)
            }
            else {
              element.selected = false;
            }

          }


        });
      }
    }
    else {
      this.availablePaymentModes.forEach(element => {
        if (element.id === selectedModeData.id) {

          element.selected = false;
        }
        if (element.id !== selectedModeData.id) {

          element.isDisabled = true;
        }



      });
    }
  }
  handleResetPayementSelected() {
    this.isAmountEnterdValidated = false;
    this.settlePayementPayLoad = {};
    this.returnAmount = 0
    this.recievedAmount = 0
    let PayLoad = { "optMode": "bindPaymentMode" }
    this.viewTableDetailsService.getDropDownLists(PayLoad).subscribe(data => {
      let tempData: any = [];
      tempData = data;

      this.availablePaymentModes = tempData.map(function (el) {
        let o = Object.assign({}, el);
        o["Amount"] = 0;
        o["selected"] = false;
        o["FinalAmountEnterd"] = "";
        o["isDisabled"] = false;
        return o;
      })
    })
  }
  calculateRecivedAmount(data) {
    

    let finalAmout = 0;
    let amount = Number(data.FinalAmountEnterd);
    this.isAmountEnterdValidated = false
    if (amount) {
      this.settlePayementPayLoad = { ...this.settlePayementPayLoad, [data.id]: amount }

    }
    let size = Object.keys(this.settlePayementPayLoad).length;
    if (size && size == 1) {
      if (amount < this.bookingTaxDetails.finalAmount) {
        this.toastr.error("please add value equal or greater then amount")
        //to ask below
        this.returnAmount = 0
      }
      else {
        let payLoad = "";
        for (let i in this.settlePayementPayLoad) {

          payLoad = i + '~' + this.settlePayementPayLoad[i]
        }
        if (amount > this.bookingTaxDetails.finalAmount) {
          this.returnAmount = amount - parseInt(this.bookingTaxDetails.finalAmount);
          this.recievedAmount = amount
        } else {
          this.returnAmount = 0
          this.recievedAmount = amount
        }

        this.isAmountEnterdValidated = true
        this.settlePayementFinalPayLoad = payLoad

      }
    }
    //multiple payement mode validations
    else {

      for (let i in this.settlePayementPayLoad) {

        finalAmout = Number(parseInt(this.settlePayementPayLoad[i]) + finalAmout);
      }
      if (finalAmout < this.bookingTaxDetails.finalAmount) {
        this.toastr.error("please add value equal or greater then amount")
        this.returnAmount = 0
      }
      else if (finalAmout == this.bookingTaxDetails.finalAmount) {

        this.returnAmount = finalAmout - parseInt(this.bookingTaxDetails.finalAmount);
        this.recievedAmount = finalAmout
        let payLoad = "";
        for (let i in this.settlePayementPayLoad) {

          payLoad = payLoad + (i + '~' + this.settlePayementPayLoad[i] + "|")
        }
        this.settlePayementFinalPayLoad = payLoad
        this.isAmountEnterdValidated = true;
      }

      else {
        // to check below
        this.toastr.error("please add value equal to amount");
        this.returnAmount = 0;
        this.recievedAmount = 0;
        this.isAmountEnterdValidated = false;
      }



    }
  }
  
}
