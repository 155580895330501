export class AppSettings {
    
    public static serverUrl = 'http://api.virtuvian.com';
   
    public static authenticateUser = AppSettings.serverUrl + "/user/Authenticate";
    public static getTodayStatus = AppSettings.serverUrl + "/transaction/getTodayStatus";
     
    public static getTables = AppSettings.serverUrl + "/transaction/getTables";
    public static getDataForDropDown = AppSettings.serverUrl + "/ddlBinder/fillDDL";
    public static getUserDetaisByMobileno = AppSettings.serverUrl + "/transaction/getCustomerByMobile";

    public static getKotDetails = AppSettings.serverUrl + "/transaction/getKotDetails";
    public static printKotDetails = AppSettings.serverUrl + "/transaction/printKotDetails";
    public static viewKotDetailsByBookingId = AppSettings.serverUrl + "/transaction/viewKotDetailsByBookingId";
    public static getTaxDetails = AppSettings.serverUrl + "/transaction/getTaxDetails";

    public static getBookingDetails = AppSettings.serverUrl + "/transaction/getBookingDetails";
   
    public static getFoodName = AppSettings.serverUrl + "/transaction/getFoodByName";
    public static getCustomerDetails = AppSettings.serverUrl + "/transaction/getCustomerDetail";
    public static saveData = AppSettings.serverUrl + "/transaction/save";
    public static printBill = AppSettings.serverUrl + "/transaction/printBillById";
    
    public static checkPin = AppSettings.serverUrl + "/transaction/checkDiscountPinDetails";
    public static reKotPrint = AppSettings.serverUrl + "/transaction/rePrintDeletedKotDetails";

      }