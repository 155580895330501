import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import { ViewTablesComponent } from './table/view-tables/view-tables.component';
import { ViewTableDetailsComponent } from './table/view-table-details/view-table-details.component';
 
const routes: Routes = [
    { path: '', redirectTo: '/table', pathMatch: 'full' },
    // { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'table', component: ViewTablesComponent,  canActivate: [AuthGuard] },
    { path: 'table-details/:bookingId', component: ViewTableDetailsComponent,  canActivate: [AuthGuard] },
    { path: 'transaction/:orderType', component: ViewTableDetailsComponent ,  canActivate: [AuthGuard] },
    { path: 'transactions/:orderType', component: ViewTableDetailsComponent ,  canActivate: [AuthGuard]},
    // otherwise redirect to home
    { path: '**', redirectTo: 'login' }
];

export const appRoutingModule = RouterModule.forRoot(routes);